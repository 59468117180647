import React from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "react-hook-form-devtools";

type Inputs = {
  example: string,
  exampleRequired: string,
};

export default function SimpleForm() {
  const { register, control, handleSubmit, watch, errors } = useForm<Inputs>();
  const onSubmit = data => console.log(data);

  console.log(watch("example"));
  
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <input name="example" defaultValue="test" ref={register} />
      
      <input name="exampleRequired" ref={register({ required: true })} />
      {errors.exampleRequired && <span>This field is required</span>}      
      <input type="submit" />
    </form>
    <DevTool control={control} />
    </>
  );
}