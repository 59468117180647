import React from "react";
import { useForm } from "react-hook-form";

import SimpleForm from "../components/SimpleForm"


function TestPushJs() {
    return (
        <SimpleForm></SimpleForm>
    )
}

export default TestPushJs